import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Banner } from '~/graphql/types'
import Breadcrumbs from '~/components/elements/Breadcrumbs'
import { LossePlaatjie } from '@ubo/losse-sjedel'
import Links from '~/components/elements/Links'
import clsx from 'clsx'

export default function BannerImage({ fields, index }: { fields: Page_Flexcontent_Flex_Banner; index: number }) {
  const isFirstElement = index === 0

  return (
    <section data-component="BannerImage" className="section section--with-bg relative">
      {fields?.image && (
        <div className="h-full w-full left-0 top-0 overflow-hidden absolute -z-10">
          <LossePlaatjie
            loading="eager"
            className={clsx(fields?.imagemobile && 'max-sm:hidden', ' w-full h-full object-cover')}
            src={fields.image}
            maxwidth={2000}
          />
          {fields?.imagemobile && (
            <LossePlaatjie loading="eager" className="sm:hidden w-full h-full object-cover" src={fields.imagemobile} maxwidth={640} />
          )}
        </div>
      )}
      <div className="container h-full w-full">
        {isFirstElement && (
          <div className="absolute max-lg:top-4 lg:top-11">
            <Breadcrumbs />
          </div>
        )}
        <div className="flex flex-col justify-start lg:w-1/2 lg:pl-16 pt-8">
          {fields?.title && (
            <div>
              {fields?.title &&
                (isFirstElement ? (
                  <h1 className="flex max-sm:flex-col flex-wrap md:text-center py-2">
                    {fields?.title?.split(' | ').map((part, key) => (
                      <span
                        key={key}
                        className={clsx(
                          'title--normal normal-case text-white font-semibold mx-1 lg:mx-2',
                          key !== 0 ? 'font-semibold' : ''
                        )}
                      >
                        {part}
                      </span>
                    ))}
                  </h1>
                ) : (
                  <h2 className="flex max-sm:flex-col flex-wrap md:text-center py-2">
                    {fields?.title?.split(' | ').map((part, key) => (
                      <span
                        key={key}
                        className={clsx(
                          'title--normal normal-case text-white font-semibold mx-1 lg:mx-2',
                          key !== 0 ? 'font-semibold' : ''
                        )}
                      >
                        {part}
                      </span>
                    ))}
                  </h2>
                ))}
            </div>
          )}
          {fields?.description && (
            <div className="pt-5">
              <Content className="content children-p:text-white">{fields?.description}</Content>
            </div>
          )}
          {fields?.links && (
            <div className="pt-10 lg:pt-12">
              <Links items={fields.links} />
            </div>
          )}
        </div>
      </div>
    </section>
  )
}
