import type { Merk } from '~/graphql/types'
import { LossePlaatjie } from '@ubo/losse-sjedel'
import { Arrow } from '~/components/elements/Arrow'
import { useState } from 'react'

interface BrandsProps {
  fields: {
    brandsItems: Merk[]
  }
}

export default function Brands({ fields }: BrandsProps) {
  const [index, setIndex] = useState(0)

  const brandItems = fields.brandsItems as unknown as Merk[]

  return (
    <div className="flex flex-row mt-8 md:mt-16 gap-1 md:gap-8 items-center">
      <div
        className="h-full cursor-pointer hidden lg:block"
        onClick={() => {
          if (index === 0) {
            setIndex(brandItems.length - 1)
          } else {
            setIndex(index - 1)
          }
        }}
      >
        <Arrow orientation="left" color="white" hover="accent" />
      </div>
      <div key={index} className="max-lg:flex max-lg:flex-col lg:grid lg:min-h-[490px] xl:min-h-[530px] lg:grid-cols-2 gap-10 xl:gap-16">
        <div className="flex flex-row gap-4 md:gap-8 items-center">
          <div
            className="h-full flex items-center cursor-pointer lg:hidden"
            onClick={() => {
              if (index === 0) {
                setIndex(brandItems.length - 1)
              } else {
                setIndex(index - 1)
              }
            }}
          >
            <Arrow orientation="left" color="white" hover="accent" />
          </div>
          <div className="h-full w-full left-0 top-0 overflow-hidden rounded-[7px]">
            <LossePlaatjie
              loading="eager"
              className="w-full h-full object-cover rounded-[7px] animate-fade-left animate-ease-in-out"
              src={brandItems[index].featuredImage.node}
              maxwidth={530}
            />
          </div>
          <div
            className="h-full flex items-center cursor-pointer lg:hidden"
            onClick={() => {
              if (index === brandItems.length - 1) {
                setIndex(0)
              } else {
                setIndex(index + 1)
              }
            }}
          >
            <Arrow color="white" hover="accent" />
          </div>
        </div>
        <div className="children:text-white flex flex-col animate-fade-left animate-ease-in-out animate-delay-75 lg:justify-center">
          <div className="font-semibold text-3xl">{brandItems[index].title}</div>
        </div>
      </div>

      <div
        className="h-full cursor-pointer hidden lg:block"
        onClick={() => {
          if (index === brandItems.length - 1) {
            setIndex(0)
          } else {
            setIndex(index + 1)
          }
        }}
      >
        <Arrow color="white" hover="accent" />
      </div>
    </div>
  )
}
