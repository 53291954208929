import type { ComponentPropsWithoutRef } from 'react'
import { LosseLink } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import type { icons } from '~/types/general'

export interface ButtonProps extends ComponentPropsWithoutRef<'a'> {
  onClick?: () => void
  to?: string
  icons?: icons
  buttonstyle?: string
  index?: number
}

const ICON_FILL_CLASSES: { [key: string]: string } = {
  accent: 'fill-white',
  conversion: 'fill-white',
  base: 'fill-white',
  base_outlined: 'group-hover:fill-white transition-all duration-200 fill-site-base',
  white_outlined: 'group-hover:fill-site-base transition-all duration-200 fill-white'
}

const LINK_CLASSES: { [key: string]: string } = {
  accent: 'btn--accent',
  conversion: 'btn--conversion',
  base: 'btn--base',
  base_outlined: 'btn--base-outlined',
  white_outlined: 'btn--white-outlined'
}

export default function Button({ children, index, className = '', buttonstyle = 'accent', icons = 'arrow', ...props }: ButtonProps) {
  return (
    <LosseLink
      className={clsx(
        LINK_CLASSES[buttonstyle || 'accent'],
        className && className,
        icons === 'mail' && '!gap-3',
        icons === 'phone' && '!gap-3',
        icons === 'combo' && '!gap-3',
        'btn group'
      )}
      {...props}
    >
      {(icons === 'mail' || (icons === 'combo' && index === 0)) && (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="22" viewBox="0 0 24 22" fill="none">
          <path
            d="M21 0.000244141H3C2.20435 0.000244141 1.44129 0.316315 0.87868 0.878924C0.31607 1.44153 0 2.20459 0 3.00024L0 22.0002H24V3.00024C24 2.20459 23.6839 1.44153 23.1213 0.878924C22.5587 0.316315 21.7956 0.000244141 21 0.000244141ZM3 2.00024H21C21.2652 2.00024 21.5196 2.1056 21.7071 2.29314C21.8946 2.48067 22 2.73503 22 3.00024V3.66724L14.122 11.5462C13.5584 12.1076 12.7954 12.4227 12 12.4227C11.2046 12.4227 10.4416 12.1076 9.878 11.5462L2 3.66724V3.00024C2 2.73503 2.10536 2.48067 2.29289 2.29314C2.48043 2.1056 2.73478 2.00024 3 2.00024ZM2 20.0002V6.50024L8.464 12.9602C9.40263 13.8965 10.6743 14.4223 12 14.4223C13.3257 14.4223 14.5974 13.8965 15.536 12.9602L22 6.50024V20.0002H2Z"
            className={ICON_FILL_CLASSES[buttonstyle || 'accent']}
          />
        </svg>
      )}
      {(icons === 'phone' || (icons === 'combo' && index === 1)) && (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <g clipPath="url(#clip0_890_1153)">
            <path
              d="M14.2002 16.261C11.2886 15.0282 8.97325 12.7075 7.7472 9.793L11.1472 6.387L4.8872 0.122L1.7162 3.292C1.1662 3.84514 0.731342 4.50186 0.436795 5.22415C0.142247 5.94645 -0.00614821 6.71998 0.000195101 7.5C0.000195101 14.748 9.25219 24 16.5002 24C17.2801 24.0068 18.0536 23.8586 18.7758 23.5641C19.498 23.2695 20.1545 22.8344 20.7072 22.284L23.8782 19.113L17.6132 12.848L14.2002 16.261ZM19.2922 20.87C18.925 21.234 18.489 21.5214 18.0097 21.7154C17.5304 21.9094 17.0172 22.0061 16.5002 22C10.2672 22 2.0002 13.733 2.0002 7.5C1.99431 6.98283 2.09116 6.46964 2.28514 5.99019C2.47911 5.51074 2.76635 5.07458 3.1302 4.707L4.8872 2.95L8.3242 6.387L5.38919 9.322L5.63419 9.936C6.35544 11.8653 7.48296 13.6171 8.94035 15.0725C10.3977 16.528 12.1509 17.6533 14.0812 18.372L14.6872 18.603L17.6132 15.676L21.0502 19.113L19.2922 20.87ZM14.0002 2V0C16.6515 0.0029116 19.1933 1.05742 21.068 2.93215C22.9428 4.80688 23.9973 7.34873 24.0002 10H22.0002C21.9978 7.879 21.1542 5.84555 19.6544 4.34578C18.1546 2.846 16.1212 2.00238 14.0002 2ZM14.0002 6V4C15.591 4.00159 17.1162 4.63424 18.2411 5.75911C19.366 6.88399 19.9986 8.40919 20.0002 10H18.0002C18.0002 8.93913 17.5788 7.92172 16.8286 7.17157C16.0785 6.42143 15.0611 6 14.0002 6Z"
              className={ICON_FILL_CLASSES[buttonstyle || 'accent']}
            />
          </g>
          <defs>
            <clipPath id="clip0_890_1153">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      )}
      {children}
      {icons === 'arrow' && (
        <svg width="38" height="22" viewBox="0 0 38 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_709_2068)">
            <path
              d="M37.4628 9.00339L0.536536 8.99674C0.240494 8.99674 0 8.74337 0 8.4308C0 8.11823 0.240494 7.86552 0.536536 7.86552L28.0444 7.87018L24.6642 0.969115C24.5544 0.744998 24.5992 0.471004 24.7734 0.298095C24.9477 0.125186 25.2102 0.0945947 25.416 0.224276L37.7374 7.95197C37.9451 8.08232 38.0448 8.34235 37.9804 8.58775C37.916 8.83315 37.7046 9.00339 37.4628 9.00339ZM29.2526 7.87084L35.5111 7.87217L26.479 2.20674L29.2532 7.87084H29.2526Z"
              className={ICON_FILL_CLASSES[buttonstyle || 'accent']}
            />
            <path
              d="M25.1421 21.855C25.0083 21.855 24.8763 21.8025 24.7741 21.7014C24.5999 21.5285 24.555 21.2545 24.6655 21.0304L28.0457 14.1346L0.536536 14.1346C0.240494 14.134 0 13.8806 0 13.5687C0 13.2568 0.240494 13.0034 0.536536 13.0034L37.4628 13.0034C37.7046 13.0034 37.916 13.1737 37.9804 13.4191C38.0442 13.6645 37.9451 13.9252 37.7374 14.0548L25.416 21.7759C25.3308 21.8291 25.2361 21.8557 25.1421 21.8557V21.855ZM29.2538 14.134L26.4796 19.7934L35.5111 14.134H29.2545H29.2538Z"
              className={ICON_FILL_CLASSES[buttonstyle || 'accent']}
            />
          </g>
          <defs>
            <clipPath id="clip0_709_2068">
              <rect width="37.9998" height="21.7113" fill="white" transform="translate(0 0.144348)" />
            </clipPath>
          </defs>
        </svg>
      )}
    </LosseLink>
  )
}
