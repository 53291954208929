import type { Page_Flexcontent_Flex_Posts, Teamlid } from '~/graphql/types'
import { LossePlaatjie } from '@ubo/losse-sjedel'
import { Arrow } from '~/components/elements/Arrow'
import { useState } from 'react'
import { Subtitle } from '~/components/elements/Subtitle'
import { Title } from '~/components/elements/Title'
import Content from '~/components/elements/Content'
import Brands from './highlighted-types/brands'
import Products from './highlighted-types/Products'
import Projects from './highlighted-types/Projects'

export default function TeamHighlighted({ fields }: { fields: Page_Flexcontent_Flex_Posts }) {
  const [index, setIndex] = useState(0)

  const teammembers = fields.posts.edges as unknown as { node: Teamlid }[]

  const customItemsComponents: Record<string, JSX.Element | null> = {
    brands: <Brands fields={{ brandsItems: fields?.brandsItems }} />,
    products: <Products fields={{ productsItems: fields?.products }} />,
    projects: <Projects fields={{ projectItems: fields?.projects }} />,
    none: (
      <div className="flex flex-row mt-8 md:mt-16 gap-1 md:gap-8 items-center">
        <div
          className="h-full cursor-pointer hidden lg:block"
          onClick={() => setIndex((prevIndex) => (prevIndex === 0 ? teammembers.length - 1 : prevIndex - 1))}
        >
          <Arrow orientation="left" color="white" hover="accent" />
        </div>
        <div key={index} className="max-lg:flex max-lg:flex-col lg:grid lg:min-h-[490px] xl:min-h-[530px] lg:grid-cols-2 gap-10 xl:gap-16">
          <div className="flex flex-row gap-4 md:gap-8 items-center">
            <div
              className="h-full flex items-center cursor-pointer lg:hidden"
              onClick={() => setIndex((prevIndex) => (prevIndex === 0 ? teammembers.length - 1 : prevIndex - 1))}
            >
              <Arrow orientation="left" color="white" hover="accent" />
            </div>
            <div className="h-full w-full left-0 top-0 overflow-hidden rounded-[7px]">
              <LossePlaatjie
                loading="eager"
                className="w-full h-full object-cover rounded-[7px] animate-fade-left animate-ease-in-out"
                src={teammembers[index].node.featuredImage.node}
                maxwidth={530}
              />
            </div>
            <div
              className="h-full flex items-center cursor-pointer lg:hidden"
              onClick={() => setIndex((prevIndex) => (prevIndex === teammembers.length - 1 ? 0 : prevIndex + 1))}
            >
              <Arrow color="white" hover="accent" />
            </div>
          </div>
          <div className="children:text-white flex flex-col animate-fade-left animate-ease-in-out animate-delay-75 lg:justify-center">
            <div className="font-semibold text-3xl">{teammembers[index].node.title}</div>
            {teammembers[index]?.node?.recap?.description && (
              <Content className="children-p:text-white mt-6 pb-8">{teammembers[index]?.node?.recap?.description}</Content>
            )}
            {teammembers[index]?.node?.recap?.emailAddress && (
              <a
                target="_blank"
                rel="noopener noreferrer"
                className="flex hover:text-site-accent transition-all duration-200 items-center gap-3 font-semibold mb-5"
                href={`mailto:${teammembers[index]?.node?.recap?.emailAddress}`}
              >
                {/* SVG Icon */}
                {teammembers[index]?.node?.recap?.emailAddress}
              </a>
            )}
            {teammembers[index]?.node?.recap?.phoneNumber && (
              <a
                target="_blank"
                rel="noopener noreferrer"
                className="flex hover:text-site-accent transition-all duration-200 items-center gap-3 font-semibold"
                href={`tel:${teammembers[index]?.node?.recap?.phoneNumber}`}
              >
                {/* SVG Icon */}
                {teammembers[index]?.node?.recap?.phoneNumber}
              </a>
            )}
          </div>
        </div>

        <div
          className="h-full cursor-pointer hidden lg:block"
          onClick={() => setIndex((prevIndex) => (prevIndex === teammembers.length - 1 ? 0 : prevIndex + 1))}
        >
          <Arrow color="white" hover="accent" />
        </div>
      </div>
    )
  }

  return (
    <section data-component="TeamHighlighted" className="section section--with-bg bg-site-base">
      <div className="container flex flex-col gap-4 items-start md:items-center">
        <Subtitle type="dark">{fields?.subtitle}</Subtitle>
        <Title type="dark" size="small">
          {fields?.title}
        </Title>

        {customItemsComponents[fields?.customItems] || null}
      </div>
    </section>
  )
}
