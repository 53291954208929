import { LosseLink, LossePlaatjie } from '@ubo/losse-sjedel'
import { Arrow } from '~/components/elements/Arrow'
import Slider, { Slide } from '~/components/elements/Slider'
import { Navigation } from 'swiper/modules'
import { Subtitle } from '~/components/elements/Subtitle'
import type { Page_Flexcontent_Flex_Posts } from '~/graphql/types'
import Links from '~/components/elements/Links'
import { Title } from '~/components/elements/Title'
import clsx from 'clsx'
import Content from '~/components/elements/Content'
import { useIsSM } from '~/hooks/useIsMobile'

export default function LinkSlider({ fields, index }: { fields: Page_Flexcontent_Flex_Posts; index: number }) {
  const theme = fields.background === '#E9EAEA' ? 'light' : 'dark'
  const background = fields.background === '#E9EAEA' ? 'light' : fields.background === '#56585E' ? 'grey' : 'dark'
  const isSM = useIsSM()

  return (
    <section
      data-component="LinkSlider"
      className={clsx(
        'relative overflow-hidden section section--with-bg',
        background === 'light' && 'bg-site-grey',
        background === 'grey' && 'bg-site-alt',
        background === 'dark' && 'bg-site-base'
      )}
    >
      <div className="container">
        <div className={clsx((theme === 'dark' || theme === 'light') && 'lg:px-10', 'grid grid-cols-1 lg:grid-cols-2')}>
          <div className={clsx((theme === 'dark' || theme === 'light') && !!fields.description ? `col-span-1` : `lg:col-span-2`)}>
            <Subtitle type={theme}>{fields.subtitle}</Subtitle>
            <Title className={clsx(fields.description && 'sm:mt-3')} size={fields.description ? 'small' : 'medium'} type={theme}>
              {fields.title}
            </Title>
          </div>
          {fields.description && (
            <Content className={clsx(theme === 'dark' && 'children-p:text-white', 'mt-6')}>{fields.description}</Content>
          )}
        </div>
        <div className="mt-6 sm:mt-14">
          <div className="flex items-center gap-6 mb-4 sm:mb-6">
            <div id={`link_slider_prev_${index}`} className="cursor-pointer">
              <Arrow orientation="left" color={theme === 'dark' ? 'white' : 'base'} hover="accent" size={isSM ? 'small' : 'medium'} />
            </div>
            <div id={`link_slider_next_${index}`} className="cursor-pointer">
              <Arrow color={theme === 'dark' ? 'white' : 'base'} hover="accent" size={isSM ? 'small' : 'medium'} />
            </div>
          </div>
          <Slider
            modules={[Navigation]}
            navigation={{
              prevEl: `#link_slider_prev_${index}`,
              nextEl: `#link_slider_next_${index}`
            }}
            loop={fields.items.length > 1} // Only enable loop if there are multiple slides
            //loopAdditionalSlides={fields.items.length > 3 ? fields.items.length - 1 : 0}
            // initialSlide={0}
            breakpoints={{
              0: {
                slidesPerView: Math.min(fields.items.length, 1.3),
                spaceBetween: 16
              },
              1024: {
                slidesPerView: Math.min(fields.items.length, 2.3),
                spaceBetween: 28
              },
              1280: {
                slidesPerView: Math.min(fields.items.length, 3.3),
                spaceBetween: 28
              }
            }}
            className="max-sm:!-mr-6 !pl-1 !pr-1 !py-1 max-sm:!pr-6"
          >
            {fields.items.map((item, i) => {
              return (
                <Slide
                  className={clsx(
                    background === 'grey' ? 'bg-site-base hover:bg-site-alt-400' : 'bg-site-alt hover:bg-site-base',
                    ' overflow-hidden group !transition-all !duration-200 aspect-square rounded-[7px] shadow-site-shadow'
                  )}
                  key={item?.relationitem?.databaseId || `${item?.relationitem?.title}-${i}`}
                >
                  <LosseLink
                    to={item.normallink ? item?.link?.url : item?.relationitem?.uri}
                    className="flex py-4 sm:py-6 px-4 sm:px-8 items-end h-full"
                  >
                    {item?.image ? (
                      <LossePlaatjie
                        maxwidth={1000}
                        className="absolute group-hover:opacity-50 transition-all duration-200 -z-10 object-cover h-full w-full top-0 left-0 rounded-[7px]"
                        src={item.image}
                      />
                    ) : (
                      <LossePlaatjie
                        maxwidth={1000}
                        className="absolute group-hover:opacity-50 transition-all duration-200 -z-10 object-cover h-full w-full top-0 left-0 rounded-[7px]"
                        src={item.relationitem.featuredImage.node}
                      />
                    )}
                    <div className="flex gap-3 w-full items-end justify-between">
                      <span
                        className={clsx(
                          fields.textcolor === 'white' && 'text-white',
                          fields.textcolor === 'black' && 'text-site-base',
                          item?.relationitem?.uri?.includes('projectinrichting') && 'text-white',
                          'text-xl sm:text-3xl !leading-[1] font-semibold'
                        )}
                      >
                        {item.normallink ? item?.link?.title : item?.replacementtitle || item?.relationitem?.title}
                      </span>
                      <Arrow size="medium" />
                    </div>
                  </LosseLink>
                </Slide>
              )
            })}
          </Slider>
          {fields.links && (
            <div className="flex mt-6 sm:mt-16 justify-center ">
              <Links items={fields.links} />
            </div>
          )}
        </div>
      </div>
    </section>
  )
}
